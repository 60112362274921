import React from "react";
import { useStoreState } from "../../hooks/ep";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { Spinner } from "@blueprintjs/core";
import _ from "lodash";
import { AgColDefs } from "../../components/AgTable/types";
import { useMeData } from "../../hooks/useMe";
import AgTable from "../../components/AgTable";
import { getStudentBuzzUrl, getStudentGeniusUrl } from "./ecp-student-entity";
import { DEFAULT_STUDENT_ID } from "../../components/splitviews/ecp-student-enrollments-splitview";
import { DEFAULT_TEACHERCOURSE_ID } from "../../components/splitviews/ecp-teachercourse-enrollments-splitview";
import { getEnrollmentTagsInfo, getTagsColumnWidth } from "./tags";
import { TableRow } from "../../store/table-model-factory";
import { CAN_VIEW_ENROLLMENT_EMAIL_ACTIONS } from "../../components/table-actions/enrollment-email-action-cell-renderer";
import { CAN_READ_FINANCIALS__ROLES } from "../helpers/authorization";

export function EcpEnrollmentTable({
  fullScreenEnabled = true,
  studentId = DEFAULT_STUDENT_ID,
  teacherCourseId = DEFAULT_TEACHERCOURSE_ID,
  whitelistedTagIds = null,
  prefilterTagId = null,
  prefilterRegularlyPacedCourses = false,
  ...restProps
}) {
  const loading = useEnsureData("ecp_enrollment");
  return loading ? (
    <Spinner />
  ) : (
    <EcpEnrollmentTableInner
      fullScreenEnabled={fullScreenEnabled}
      studentId={studentId}
      teacherCourseId={teacherCourseId}
      prefilterTagId={prefilterTagId}
      whitelistedTagIds={whitelistedTagIds}
      prefilterRegularlyPacedCourses={prefilterRegularlyPacedCourses}
      {...restProps}
    />
  );
}

function filterToEnrollmentsForStudent(rowData: TableRow[], studentId) {
  return rowData.filter((row) => row.StudentIndex === studentId);
}

function filterToEnrollmentsForTeachercourse(
  rowData: TableRow[],
  teacherCourseId
) {
  return rowData.filter((row) => row.teacherCourse_id === teacherCourseId);
}

function EcpEnrollmentTableInner({
  studentId,
  teacherCourseId,
  prefilterTagId,
  whitelistedTagIds,
  tableName = null,
  tableNameSuffix = null,
  prefilterRegularlyPacedCourses,
  ...restProps
}) {
  prefilterTagId = prefilterTagId || null;
  whitelistedTagIds = whitelistedTagIds || null;

  let rowData = useEnrollments();

  if (studentId !== DEFAULT_STUDENT_ID) {
    rowData = filterToEnrollmentsForStudent(rowData, studentId);
    const studentName = rowData.find((row) => row.customer_name)?.customer_name;
    if (tableNameSuffix && studentName) {
      tableNameSuffix = `${tableNameSuffix}-${studentName.replace(" ", "_")}`;
    }
  }

  if (teacherCourseId !== DEFAULT_TEACHERCOURSE_ID) {
    rowData = filterToEnrollmentsForTeachercourse(rowData, teacherCourseId);
  }

  if (!_.isNull(prefilterTagId)) {
    rowData = filterToEnrollmentsWithTag(rowData, prefilterTagId);
  }

  if (prefilterRegularlyPacedCourses) {
    rowData = filterRegularlyPacedCourseEnrollments(rowData);
  }

  // rowData = useMemo(() => addTagsField(rowData, whitelistedTagIds), [
  //   rowData,
  //   whitelistedTagIds,
  // ]);

  const colDefs = useColumnDefs(studentId, whitelistedTagIds);

  return (
    <AgTable
      rowData={rowData}
      columnDefs={colDefs}
      tableName={tableName || "ecp_enrollment"}
      tableNameSuffix={tableNameSuffix}
      initialCategoryFilters={INITIAL_CATEGORY_FILTERS}
      {...restProps}
    />
  );
}

const INITIAL_CATEGORY_FILTERS = {
  is_irregularly_paced_course: [false],
  // is_seminar_course: [false],
  // is_symposium_course: [false],
};

function useEnrollments(): TableRow[] {
  return useStoreState((s) => s.ecp_enrollment.initialData);
}

function filterRegularlyPacedCourseEnrollments(rowData: TableRow[]) {
  return rowData.filter((row) => !row.is_irregularly_paced_course);
}

function filterToEnrollmentsWithTag(
  enrollments: TableRow[],
  tagId: string
): TableRow[] {
  const tagField = `tag__${tagId}`;
  return enrollments.filter((e) => e[tagField]);
}

export function getEnrollmentBuzzUrl(args) {
  const buzzId =
    args?.eLMSID ??
    args?.enrollment_buzz_id ??
    args?.LMSID ??
    args?.data?.eLMSID ??
    args?.data?.enrollment_buzz_id ??
    args?.data?.LMSID ??
    args?.value ??
    args;

  return `https://lss.agilixbuzz.com/admin/2190401/enrollment/${buzzId}/grades/grades`;
}

function useColumnDefs(studentId, whitelistedTagIds): AgColDefs {
  const isSpecificStudent = studentId !== DEFAULT_STUDENT_ID;

  const enrollmentTagsInfo = getEnrollmentTagsInfo(whitelistedTagIds);
  const tagsColDef = {
    headerName: "Tags",
    field: "tags",
    type: "tagsColumn",
    // cellRenderer: "tagsCellRenderer",
    pinned: "right",
    width: getTagsColumnWidth(enrollmentTagsInfo),
    filterParams: {
      fp: enrollmentTagsInfo,
    },
    cellRendererParams: {
      crp: enrollmentTagsInfo,
    },
  };
  const { role: myRole, timezone: myTz } = useMeData();
  const ret: AgColDefs = [
    {
      headerName: "Buzz Enr",
      field: "eLMSID",
      type: "linkedTextColumn",
      __linkValueGetter: getEnrollmentBuzzUrl,
      __linkIsNotAnEmail: true,
      // __linkField: "enrollment_buzz_url",
      pinned: "left",
      // type: "integerIdentifierColumn",
      // https://lss.agilixbuzz.com/admin/2190401/enrollment/24290199/grades/grades
    },
    {
      // !isSpecificStudent && {
      headerName: "Genius Stu",
      field: "StudentIndex",
      type: "linkedTextColumn",
      __linkValueGetter: getStudentGeniusUrl,
      __linkIsNotAnEmail: true,
      // pinned: "left",
      width: 97,
    },
    {
      // !isSpecificStudent && {
      headerName: "Buzz Stu",
      field: "student_lmsid",
      type: "linkedTextColumn",
      __linkValueGetter: getStudentBuzzUrl,
      __linkIsNotAnEmail: true,
      // pinned: "left",
      // hide: true,
    },
    {
      // !isSpecificStudent && {
      headerName: "Student Name",
      field: "customer_name",
      type: "textColumn",
      width: 150,
      suppressSizeToFit: true,
      tooltipField: "customer_name",
    },
    {
      headerName: "Grade Lvl",
      field: "grade_level",
      type: "integerColumn",
      filter: "categoryFilterer",
    },
    { headerName: "Academy", field: "eAcademyStudent", type: "booleanColumn" },
    { headerName: "Subject", field: "subject_area", type: "categoryColumn" },
    {
      headerName: "Course",
      field: "CourseName",
      type: "textColumn",
      width: 225,
      suppressSizeToFit: true,
      tooltipField: "CourseName",
    },
    {
      headerName: "Sem",
      field: "is_seminar_course",
      type: "booleanColumn",
      headerTooltip:
        "Is this a Seminar course enrollment? -- Blank if false/NO; Checkmark if true/YES",
      width: 92,
      hide: true,
    },
    {
      headerName: "Sym",
      field: "is_symposium_course",
      type: "booleanColumn",
      headerTooltip:
        "Is this a Symposium course enrollment? -- Blank if false/NO; Checkmark if true/YES",
      width: 92,
      hide: true,
    },
    {
      headerName: "NH(J)S",
      field: "is_nhs_course",
      type: "booleanColumn",
      headerTooltip:
        "Is this an NHS/NHJS course enrollment? -- Blank if false/NO; Checkmark if true/YES",
      width: 110,
      hide: true,
    },
    {
      headerName: "Failure/Extension Risk",
      field: "churn_risk_score",
      type: "floatColumn",
      cellRenderer: "churnScoreCellRenderer",
      cellClass: "react-rendered-cell",
      valueGetter: (params) =>
        params.data.is_irregularly_paced_course
          ? null
          : params.data.churn_risk_score,
      width: 200,
    },
    {
      headerName: "Asgns Behind",
      field: "AssignmentsBehind",
      type: "integerColumn",
      width: 106,
    },
    {
      headerName: "Tot Asgns",
      field: "eTotalAssignments",
      type: "integerColumn",
      width: 106,
    },
    {
      headerName: "% Behind",
      field: "pct_behind",
      type: "percentageColumn",
      width: 106,
    },
    {
      headerName: "Days MIA",
      field: "n_active_days_without_submissions",
      headerTooltip: "# days since latest buzz submission",
      type: "integerColumn",
      valueGetter: (params): number =>
        params.data.is_irregularly_paced_course
          ? null
          : Math.floor(params.data.n_active_days_without_submissions),
    },
    {
      headerName: "% Course Duration Elapsed",
      field: "pct_time_elapsed",
      type: "percentageColumn",
      // headerClass: "minerva-header-cell-text",
      width: 106,
    },
    {
      headerName: "Completed Assignments (%)",
      field: "PercentCompleteAdjusted",
      type: "percentageColumn",
      width: 158,
    },
    tagsColDef,
    // {
    //   headerName: "Slow Start",
    //   field: "tag__slow_start",
    //   type: "booleanColumn",
    //   width: 77,
    // },
    // {
    //   headerName: "Extension Candidate",
    //   field: "tag__extension_candidate",
    //   type: "booleanColumn",
    //   width: 111,
    // },
    {
      headerName: "Current Grade",
      field: "eCurrentGrade",
      type: "percentageColumn",
      width: 97,
    },
    {
      headerName: "Completed Assignments (#)",
      field: "eCurrentAssignment",
      type: "integerColumn",
      width: 156,
    },
    {
      headerName: "Total Assignments",
      field: "eTotalAssignments",
      type: "integerColumn",
      width: 133,
    },
    {
      headerName: "Cutoff Date",
      field: "cutoff",
      type: "dateColumn",
    },
    {
      headerName: "Days Remaining (as of cutoff)",
      field: "days_remaining",
      type: "integerColumn",
      width: 152,
    },
    {
      headerName: "First Buzz Activity",
      field: "buzz_first_activity_date",
      type: "timestampColumn",
      __timezone: myTz,
    },
    {
      headerName: "Latest Buzz Activity",
      field: "buzz_last_activity_date",
      type: "timestampColumn",
      __timezone: myTz,
    },
    {
      headerName: "Latest Buzz Submission",
      field: "latest_buzz_submission_timestamp",
      type: "timestampColumn",
      __timezone: myTz,
    },
    { headerName: "Start", field: "eStartDate", type: "dateColumn" },
    { headerName: "Entry", field: "eEntryDate", type: "dateColumn" },
    { headerName: "End", field: "eEndDate", type: "dateColumn" },
    {
      headerName: "FC 100% Completion Date",
      field: "forecasted_100p_completion_date",
      type: "dateColumn",
      headerTooltip:
        "The date when the student is forecasted to finish the final course assignment, based on their current assignment completion pacing",
    },
    {
      headerName: "Course Length (Weeks)",
      field: "Weeks",
      type: "integerColumn",
    },
    {
      headerName: "Price",
      field: "ePrice",
      type: "moneyColumn",
      __authorized_roles: CAN_READ_FINANCIALS__ROLES,
    },
    {
      headerName: "Section",
      field: "SectionName",
      type: "textColumn",
      width: 241,
    },
    {
      headerName: "Orig Start",
      field: "initial_StartDate",
      type: "dateColumn",
    },
    { headerName: "Orig End", field: "initial_EndDate", type: "dateColumn" },
    // {
    //   headerName: "Buzz Student ID",
    //   field: "student_lmsid",
    //   type: "integerIdentifierColumn",
    // },
    {
      headerName: "Genius Enrollment ID",
      field: "EnrollmentIndex",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "Genius Section ID",
      field: "SectionIndex",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "Genius Course ID",
      field: "CourseIndex",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "",
      field: "tag__missing_in_action",
      cellClass: "react-rendered-cell",
      cellRenderer: "enrollmentEmailActionButtonCellRenderer",
      type: "textColumn",
      width: 52, // 176
      pinned: "right",
      cellRendererParams: {
        crp: {
          tooltipText: "Compose email from template",
        },
      },
      __authorized_roles: CAN_VIEW_ENROLLMENT_EMAIL_ACTIONS,
    },
    // {
    //   headerName: "[Student] All Cutoffs Are Recent",
    //   field: "all_cutoffs_are_recent",
    //   type: "booleanColumn",
    // },
    // {
    //   headerName: "[Student] MAX(% Complete) / MIN(Cutoff %)",
    //   field: "max_pct_complete__div__min_cutoff_pct_int",
    //   type: "floatColumn",
    // },
    {
      headerName: "Sem / Sym / NH(J)S / DE / LB / Info",
      field: "is_irregularly_paced_course",
      type: "booleanColumn",
      headerTooltip:
        "Course expected to have irregular pacing (e.g. Seminar, Symposium, NHS/NHJS, Dual Enrollment, Language Bird, Information)? -- Blank if false/NO; Checkmark if true/YES",
      width: 177,
    },
  ];

  return ret
    .filter((v) => !!v)
    .filter(
      // @ts-ignore
      (cd) => !cd.__authorized_roles || cd.__authorized_roles.includes(myRole)
    );
}
